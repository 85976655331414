import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "../styles/components/header.scss";
import MDCC_Logo from "../assets/images/MDCC_Logo.png";
import TwitterLogo from "../assets/images/Twitter_Logo.png";
import DiscordLogo from "../assets/images/Discord-Logo.png";
import InstagramLogo from "../assets/images/Instagram_Logo.png";
import YoutubeLogo from "../assets/images/youtube_icon.svg";
import { useMedia } from "react-use";

const linkData = [
  {
    title: "MAD DOGS",
    url: "/mad-dogs",
  },
  {
    title: "MAD DOG CARS",
    url: "/mad-dog-cars",
  },
  {
    title: "MD RACERS",
    url: "/md-racers",
  },
  {
    title: "MD LICENSE",
    url: "/md-license",
  },
  {
    title: "MD RACING",
    url: "/md-racing",
  },
];

const Header = ({ setMobileMenu }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const location = useLocation();
  const isMobile = useMedia("(max-width: 991px)");

  useEffect(() => {
    setMobileMenu(menuOpened);
  }, [menuOpened]);

  const handleRemoveMobileHeader = () => {
    if (isMobile) {
      setMenuOpened(false);
      document.querySelector("#basic-navbar-nav").style.display = "none";
    }
  };

  return (
    <Navbar className="nav-wrapper" expand="lg">
      <div className="header-container d-flex align-items-center justify-content-between">
        <Link className="navbar-home" to="/">
          {!menuOpened && (
            <img className="logo-image" src={MDCC_Logo} alt="mdcc-logo" />
          )}
        </Link>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setMenuOpened((value) => !value)}
        >
          {menuOpened ? (
            <AiOutlineClose size={36} color="#ffffff" />
          ) : (
            <AiOutlineMenu size={36} color="#ffffff" />
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto d-flex align-items-center justify-content-between flex-fill">
            {menuOpened && (
              <Link className="navbar-home" to="/">
                <img
                  className="logo-image me-0"
                  src={MDCC_Logo}
                  alt="mdcc-logo"
                />
              </Link>
            )}
            {linkData.map((link, index) => {
              // if(index == 2) {
              //   return (
              //   <NavDropdown title="MD RACERS" id="navbarScrollingDropdown" className="font-nav font-bold">
              //     <NavDropdown.Item href="/md-racers" >MD RACERS</NavDropdown.Item>
              //     <NavDropdown.Item href="https://maddogcarclub.ada-anvil.io/en/" >MINT NOW</NavDropdown.Item>
              //     <NavDropdown.Item href="/md-leaderboard" >LEADERBOARD</NavDropdown.Item>
              //     <NavDropdown.Item href="/check-allocation" >CHECK AllOCATION</NavDropdown.Item>
              //     <NavDropdown.Item href="/mint-process" >MINT PROCESS</NavDropdown.Item>
              //   </NavDropdown>
              //   )
              // }else{
              return(
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => {
                  if (isMobile) setMenuOpened((value) => !value);
                }}
                key={index}
              >
                <Link
                  className={
                    "font-nav font-bold d-flex justify-content-center text-nowrap" +
                    (location.pathname.includes(link.url) && "active")
                  }
                  to={link.url}
                >
                  {link.title}
                </Link>
              </Navbar.Toggle>
              )
              // }
              
              })}




            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => {
                if (isMobile) setMenuOpened((value) => !value);
              }}
            >
              <a
                className="font-nav font-bold"
                href="https://mdtoken.io"
                target="_black"
              >
                MD TOKEN
              </a>
            </Navbar.Toggle>
            <div className="social-panel d-flex align-items-center justify-content-center">
              <a
                href="https://twitter.com/MadDogCarClub?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TwitterLogo} alt="twitter-logo" />
              </a>
              <a
                href="https://www.instagram.com/maddogcarclub/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramLogo} alt="instagram-logo" />
              </a>
              <a
                href="https://discord.com/invite/maddogcarclub"
                target="_blank"
                rel="noreferrer"
              >
                <img src={DiscordLogo} alt="discord-logo" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCKoR9s7EOUWLvLLx0SyxKaQ"
                target="_blank"
                rel="noreferrer"
              >
                <img src={YoutubeLogo} alt="youtube-logo" />
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
