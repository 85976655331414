import React, { useState } from 'react'
import MintProcessImage from "../assets/images/MDRacers_Mint 1.png";
import MintModal from '../components/MintModal';
import "../styles/mintProcess.scss";
const MintProcess = () => {

  return (
    <div className = 'mint-process'>
        <div>
            <p className='title'>MINT PROCESS</p>
            <img src={MintProcessImage} />
        </div>

    </div>
  )
}

export default MintProcess