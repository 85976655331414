import React, { useEffect, useState } from "react";
import "../styles/landingPage.scss";
import MDCC_Logo from "../assets/images/MDCC_Logo.png";
import { Link } from "react-router-dom";
import TwitterLogo from "../assets/images/Twitter_Logo.png";
import DiscordLogo from "../assets/images/Discord-Logo.png";
import InstagramLogo from "../assets/images/Instagram_Logo.png";
import YoutubeLogo from "../assets/images/youtube_icon.svg";
import { useMedia } from "react-use";
import Loading from "../components/Loading";
import MintModal from "../components/MintModal";

const linkList = [
  {
    name: "MAD DOGS",
    url: "/mad-dogs",
  },
  {
    name: "MAD DOG CARS",
    url: "/mad-dog-cars",
  },
  {
    name: "MD RACERS",
    url: "/md-racers",
  },
  {
    name: "MD LICENSE",
    url: "/md-license",
  },
  {
    name: "MD RACING",
    url: "/md-racing",
  },
];

const LandingPage = () => {
  const isMobile = useMedia("(max-width: 550px)");
  const [isLoading, setIsLoading] = useState(true);
  const [showMintModal, setShowMintModal] = useState(true)
  useEffect(() => {

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="landing-page">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="bg-cover">
          <div className="container">
            <div className="main-panel d-flex flex-column align-items-center justify-content-between">
              <img src={MDCC_Logo} className="mdcc-logo" alt="mdcc-logo" />
              <div className="text-panel text-center">
                <h1 className="font-h1 font-bold text-uppercase">
                  Welcome to {isMobile ? <br /> : ""}Mad Dog Car Club
                </h1>
                <h2 className="font-h2 font-thin text-uppercase">
                  HOP IN AND ENJOY THE RIDE
                </h2>
              </div>
              <div className="d-flex flex-wrap links-panel justify-content-between">
                {linkList.map((item, index) => (
                  <Link
                    className="link-item font-button1 font-bold d-flex align-items-center justify-content-center"
                    to={item.url}
                    key={index}
                  >
                    {item.name}
                  </Link>
                ))}
                <a
                  className="link-item font-button1 font-bold d-flex align-items-center justify-content-center"
                  href="https://mdtoken.io"
                  target="_black"
                >
                  MDTOKEN.IO
                </a>
              </div>
              <div className="social-panel d-flex align-items-center justify-content-center">
                <a
                  href="https://twitter.com/MadDogCarClub?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TwitterLogo} alt="twitter-logo" />
                </a>
                <a
                  href="https://www.instagram.com/maddogcarclub/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={InstagramLogo} alt="instagram-logo" />
                </a>
                <a
                  href="https://discord.com/invite/maddogcarclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={DiscordLogo} alt="discord-logo" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCKoR9s7EOUWLvLLx0SyxKaQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={YoutubeLogo} alt="youtube-logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

<MintModal show = {showMintModal} onClose ={() => setShowMintModal(false)}  />
    </div>
  );
};
export default LandingPage;
