import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/mainLayout";
import LandingPage from "./pages/LandingPage";
import MadDogsCarsPage from "./pages/MadDogsCarsPage";
import MadDogsPage from "./pages/MadDogsPage";
import MdLicense from "./pages/MdLicense";
import MadRacers from "./pages/MadRacers";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import MdRacing from "./pages/MDRacing";
import MDRanking from "./pages/MDRanking";
import CheckAllocation from "./pages/CheckAllocation";
import MintProcess from "./pages/MintProcess";
import MDLeaderboard from "./pages/MDLeaderboard";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="/mad-dogs" element={<MadDogsPage />} />
          <Route path="/mad-dog-cars" element={<MadDogsCarsPage />} />
          <Route path="/md-racers" element={<MadRacers />} />
          <Route path="/md-license" element={<MdLicense />} />
          <Route path="/md-racing" element={<MdRacing />} />
          <Route path="/md-ranking" element={<MDRanking/>} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/check-allocation" element={<CheckAllocation />} />
          <Route path="/mint-process" element={<MintProcess />} />
          <Route path="/md-leaderboard" element={<MDLeaderboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
