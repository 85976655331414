import React, { useEffect, useState } from "react";
import "../styles/terms.scss";
import parse from "html-react-parser";
import MintModal from "../components/MintModal";

const PrivacyText1 = `PURPOSE AND APPLICABILITY<br/>
This Privacy Policy/Notice (“Notice”) sets out the privacy practices of by MRT & Co doing business as Mad Dog Car Club or “MDCC” and its affiliates, including the Sites at and its affiliates (“MDCC”) for their websites (“Sites”), mobile applications (“Apps”) and Internet-based activities (“Web Services”), including, without limitation, the Sites at https://maddogcarclub.io (including all of its subdomains). The Web Services include, without limitation, for example, the MDCC, e-mail, push notifications and SMS/MMS communications. The Sites, together with the Apps, Web Services, are the “Platform.”
<br/><br/>
The MDCC-affiliated entity that operates a Site or App is identified within that Site or App. The words “user,” “you” and “your” mean users of any part of the Platform, and the words “we,” “our”, and “us” mean the MDCC-affiliated entity that operates the applicable portion of the Platform.
<br/><br/>
This Notice explains the information that we gather about you while you use the Platform, the ways in which we use, secure, and share that information, and your rights regarding your personal information. Also, this Notice sets forth your rights specific to the State of California or other states, for example, as set forth in the California Consumer Privacy Act (“CCPA”) and other applicable California privacy laws. This Notice does not apply to any information you may provide us, or that we may collect by other means not covered in this Notice.
<br/><br/>
WHAT WE COLLECT AND HOW<br/>
We collect information through the Platform, including personal information about you. Some information you may enter of provide to us yourself, while other information, like cookie, location, and device data, we gather or derive through various means. We will retain that information for so long as you have an account or we have a legal or business purpose for it, unless otherwise stated herein. Your personal information may be collected, processed, and stored on servers in the United States and in other countries. The following explains what we collect and how we collect it.<br/>
INFORMATION YOU ENTER OR PROVIDE TO US DIRECTLY
<br/><br/>
You may provide us with information in order to use, or participate in, certain features of the Platform, such as, without limitation, any of the following: registering for an account; for signing up for e-mail newsletters; direct messaging us via Instagram or Twitter; starting a conversation with the us on; posting to MDCC-affiliated social media sites <a href="https://twitter.com/MadDogCarClub" target="_blank">https://twitter.com/MadDogCarClub</a> or <a href="https://www.instagram.com/maddogcarclub" target="_blank">https://www.instagram.com/maddogcarclub</a><br/><br/>

If you register for an account at one of the Sites, Apps, you will need to provide certain information, such as your first and last name, password, and e-mail address. You may also have the option to provide additional information for a user profile, such as your photo, demographic information, and birthdate. You may log into your account to access and edit certain information associated with your account, such as your contact information.<br/>
Online Purchases. If you make a purchase of Services on the Platform, you will need to provide the following information:`;

const PrivacyListText1 = [
  "Financial Information. To pay for your MDCC purchase we collect your credit card number and associated information, which we process in order to fulfill your purchase. In order to provide information for your receipts, past orders and to facilitate future orders you may make, we may store your credit card if you so choose.",
];
const PrivacyText2 =
  "Other Places You Provide Information. You may have the opportunity to provide information at other areas of the Platform. These include, for example and without limitation:";
const PrivacyListText2 = [
  "Communications with us.If you provide feedback through a form or contact us via e - mail, we will collect your name and e - mail address, as well as any other content or information you provide.",
  "Surveys, promotions, etc.We collect information you provide by participating in surveys, sweepstakes, contests, promotions or other solicitations.",
  "Public postings.We may also collect information you post at other areas of the Platform, such as public areas of the Platform including message boards, blogs, social media sites, and reviews.",
  "Human Verification Online.On many of our web forms on the Sites and Apps, we utilize the Google reCAPTCHA service to fight spam and abuse on our Platform and verify that you are a human and not a “bot” based on a score returned to us from Google’s servers after you interact with that third - party service.The use of reCAPTCHA is noted by the presence of a badge image that appears in the bottom right corner of a user’s screen.For clarity, web forms on the Sites and Apps that incorporate the third - party reCAPTCHA service are also subject to <a href='https://policies.google.com/privacy?hl=en-US' target='_blank'>Google’s Privacy Policy</a> and <a href='https://policies.google.com/terms?hl=en-US' target='_blank'>Google's Terms of Use.</a>",
];
const PrivacyText3 = `HOW WE USE INFORMATION</br>
Platform Operation. We use the information we collect to operate, maintain and improve the Platform and our products and services, such as to respond to comments and questions and provide customer service; send information including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages; communicate about promotions, upcoming events, and other products and services offered by us and/or by third parties, including through the use of contextual and interest-based advertising; link or combine the information we collect with other information we get from third parties, to help understand your interests and provide you with better service; or content, to improve our Platform, or to communicate with you regarding promotions, upcoming events, and other products and services offered by us and/or by third parties, including through the use of contextual and interest-based advertising; protect, investigate and deter against fraudulent, unauthorized or illegal activity; provide and deliver the products and services you request; identify you as a user in our system, such as when we feel it is necessary to enforce compliance with our <a href='https://maddogcarclub.io/#/terms' target='_blank'>https://maddogcarclub.io/#/terms</a> or to protect the Platform, customers, or other individuals; facilitate the creation of and secure any accounts you created on our Platform; develop and improve marketing and advertising for the Platform; and/or for other purposes in operating the Platform.
</br></br>
We may create de-identified information records and "aggregate data" reports about how users use the Platform, such as the number and frequency of visitors to the Sites or the Apps, which may be derived from technical and/or personal information. This information cannot be readily tied back to you, your account, your web browser, your image, or your mobile device. We may use this de-identified information for business purposes, such as to enhance the content of and improve the Platform, for example by allowing us and third-parties authorized by us to ascertain how often individuals use areas of the Platform so that we may focus on and improve those areas.
</br></br>
We may use technical data we gather to enable us or a third-party to provide you with information about products and services while you are using the Platform.
Advertising. We use analytics services, advertising companies, advertising networks, and advertising exchanges to help analyze how users use and navigate the Platform.
</br></br>
HOW WE SHARE INFORMATION</br>
WE SHARE YOUR INFORMATION WITH OTHERS AS FOLLOWS.</br>
Other Users. If you create an account, information such as your username, photo, birthday, and other information you enter may be displayed to other users of the Platform to facilitate user interaction within the Platform. We will not reveal your contact information to other users without your permission.
</br></br>
Some of your activity on and through the Platform is public by default. This may include, but is not limited to, content you post publicly through message boards, blogs, social media, or reviews on the Platform. If you choose to provide information using certain public features of the Platform, that information may be governed by the privacy settings of those particular features and may be publicly available. Individuals reading such information may use or disclose it to other individuals or entities without our control and without your knowledge, and search engines may index that information.
</br></br>
Promotions. If we sponsor surveys, sweepstakes, contests, promotions, or other solicitations for third-parties, we may share the information you provide with those third-parties. We may also share such information with third-parties that provide marketing and advertising on our behalf, as well as with our retailers.
</br></br>
We use various third-parties that may do one or more of the following activities in regard to credit card transactions: (a) validate, screen for fraud, facilitate, and process credit card transactions, (b) charge and settle credit card transactions, and/or (c) tokenize, vault and store credit card data. We require those third parties to use your information only to process the credit card transactions for us.
</br></br>
Social Media. We may share certain data, such as your mobile telephone or e-mail contact information, with social media outlets, like Facebook and Instagram, in connection with our agreement to share such information with them, in order to provide more targeted advertisements about goods and services from us that may be of interest to you.</br>
Location Data. We may share location data collected from your ISP, Sites, and/or the Apps via your mobile device with third parties that provide marketing, advertising, and analytics services on our behalf, as well as for our retailers.
</br></br>
Our Affiliates. We may share any and all of your information you have provided or we have collected, purchased, derived or produced with our trusted subsidiaries and entities in which we have an ownership or is under common control with us, for purposes of providing you with better service or content, or to communicate with you regarding promotions, upcoming events, and other products and services offered by us and/or those affiliates, including through the use of contextual and interest-based advertising.
</br></br>
Third Party Service Providers. We may share your information with third-party service providers that assist us in operating the Platform, for and on our behalf, such as a hosting company or e-mail provider. They are instructed to use the information only to assist us in operating the Platform. We may also share your information with third-party service providers other than for their assistance, such as sharing your feedback with our vendors to improve the Platform. We may also share your information with third-parties that provide marketing and advertising on our behalf, as well as with our retailers.
</br></br>
We may share de-identified data concerning retail consumer behavior with third-parties, including entities with whom we have entered into an agreement to provide such de-identified data for analytics or marketing purposes.
</br></br>
We may also share with our retailers de-identified segment information, which is information that does not identify you personally, but allows the retailers to know more generally the products being purchased through, and the consumers who are using, the Platform.
</br></br>
Required By Law or To Protect Interests. We may disclose your information to (i) comply with laws, rules, regulations, legal processes and governmental requests; (ii) enforce this Notice and our <a href='https://maddogcarclub.io/#/terms' target='_blank'>https://maddogcarclub.io/#/terms</a>, including investigation of potential violations of this Notice or the Terms of Use; (iii) detect, prevent or otherwise address fraud, security or technical issues (such as for fraud protection, credit risk reduction and spam/malware prevention); (iv) respond to user support requests; (v) avoid liability, or to protect our rights or property or the rights or property of anyone else (such as when user is believed to be in violation of our <a href='https://maddogcarclub.io/#/terms' target='_blank'>https://maddogcarclub.io/#/terms</a>; and/or (vi) protect the rights, property and safety of our companies, partners, contractors, customers, users and the public (such as to report suspected illegal activity to law enforcement).
</br></br>
Corporate Activity. If we sell assets (or the assets of a division or subsidiary) to another entity or if we are acquired by or merged with another entity, we may share with such entity information about users of the Platform that is related to that part of our business that is sold to or acquired by or merged with the other entity.
</br></br>
DO NOT SELL MY PERSONAL INFORMATION; OPT-OUT; CHILDREN; STATE RIGHTS
</br></br>
In General</br>
Children. The Platform is not directed to children under the age of 18. We do not knowingly collect personal information from children under the age of 18, nor do we knowingly distribute such information to third-parties. If we become aware that we received personal information from someone under the age of 18, we will take steps to delete such information from our records. If you believe we have personal information from someone under 18, please contact us at support@maddogcarclub.io
</br></br>
We use and disclose the information collected as explained in this Notice. How we use and disclose that information may be supplemented in the area where you provide the information.</br>
Terminating Your Account. You may terminate an account you set-up on any of the Sites, the Apps, etc. by e-mailing support@maddogcarclub.io. If you terminate your account, any association between your account and information we store will no longer be accessible through your account. We may retain the information collected prior to termination for archival and/or legal purposes, except as otherwise set forth in this Notice. Any information you shared in public areas of the Platform will remain in those areas until it is deleted in accordance with our data retention policy.
</br></br>
Do Not Track. Do not track is a voluntary program among Internet advertisers that is intended to give users the ability to opt-out of interest-based advertising. There are a few ways in which your computer may transmit a "do not track" signal to certain websites and online services you visit, and currently no standard has been established. We do not respond to do not track signals, and the settings in your browser will have no effect on the Sites.
</br></br>
Interest-based Advertising Opt-out. Among other things, the ads that are displayed to you may have the AdChoices icon or a similar mechanism which you may click to direct you to a website where you may opt-out of interest-based advertising. If you would like more information about this practice and how to opt-out of receiving communications from these third-party advertising companies, please visit this link.
</br></br>
STATE SPECIFIC RIGHTS</br>
California Privacy Rights</br>
</br></br>
The following are rights specific to consumers and shoppers who are residents of the State of California only (we may require proof of California residency in advance or in response to a request invoking CCPA rights):
</br></br>
California Privacy Protection Act (CCPA). Consumers (as defined in the CCPA) that use the Platform and who are residents of the state of California (CA) have the right to, no more than twice in any 12 month period, for activities on the Platform in the 12 months prior to such consumer’s request:`;

const PrivacyListText3 = [
  "Know what personal information is being collected by MDCC about them, for what business purpose, and from what types of sources;",
  "Access the personal information that has been collected by MDCC about them;",
  "Know if this personal information that is being collected by MDCC about them is being disclosed to a third-party or sold to a third-party, and if being sold, whom it’s being sold to *;",
  "Opt-out of the sales of any personal information that is being collected by MDCC about them *; and",
  "Request any personal information about you that has been collected by MDCC already be deleted.",
];

const PrivacyText4 = `
<br/>
* NOTE: MDCC does not sell any personal information that it collects. For full details on how we may share information on you, please see our how [insert link] we share your information section of this Notice.
<br/><br/>
To request one or more of these CCPA rights regarding the Platform, please do the following:
`;

const PrivacyListText4 = [
  "Send a formal written request to support@maddogcarclub.io with your name, email address, and if applicable, your mobile phone number (if you receive text messages from MDCC) and which CCPA right(s) you wish to invoke.",
];

const PrivacyText5 = `<br/>
Shine the Light. California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our shoppers who are California residents to request and obtain from MDCC once a year, free of charge, information about the personally identifiable information (if any) we disclosed to third-parties for direct marketing purposes in the preceding calendar year. If applicable, this information would include a list of the categories of personal information that was shared and the names and addresses of all third parties with which we shared information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to:
support@maddogcarclub.io
<br/><br/>
Please be aware that not all information sharing is covered by the "Shine the Light" requirements and only information on covered sharing will be included in our response. Also note that under California law, businesses are only required to respond to a shopper’s request once during any calendar year.
<br/><br/>
OTHER STATE PRIVACY RIGHTS<br/>
Nevada Residents. If you are a resident in the state of Nevada, please note that we do not sell your information to third-parties for any monetary compensation. Thus, any request from a resident of Nevada directing us not to sell the information we collect from you is inapplicable, and we will not respond to such requests. If you have any questions about our third-party disclosure practices, please contact us at support@maddogcarclub.io.
<br/><br/>
NON-U.S. RESIDENTS<br/>
Residents of other countries outside the U.S., such as the European Union, may have other rights. As stated in our <a href='https://maddogcarclub.io/#/terms' target='_blank'>https://maddogcarclub.io/#/terms</a>, while you may be able to setup an account as a user outside the U.S., we make no assurances or representations of any kind that the Platform is suitable for use in the country in which you reside.
<br/><br/>
By accessing the Platform, establishing an account or making a purchase, you consent to the processing of your personal information as provided in this Notice. You may withdraw that consent by contacting us at support@maddogcarclub.io. You are required to provide certain personal information in order to open and maintain an account. Your failure to provide such information may preclude you from establishing an account or may subject your account to termination. An account may be required to make certain types of online purchases, as a guest style checkout option is not always available across all of our Sites and Apps.
<br/><br/>
If at any point you believe the personal information we process about you is incorrect, you may access your account and modify it there, or otherwise request to see the information and to have it corrected or deleted by contacting us at support@maddogcarclub.io. If you wish to object to or raise a complaint on how we have handled your personal information, you may contact our Data Protection Officer who will investigate the matter. You may also be able to have your personal information transferred by contacting us at support@maddogcarclub.io.<br/>
If you are not satisfied with our response or believe we are processing your personal information not in accordance with law, you may complain to the data protection authority in your jurisdiction.
<br/><br/>
CHANGES TO THIS PRIVACY NOTICE<br/>
We may amend this Notice from time to time. All versions of the Notice are dated with the effective date of that version of the Notice (i.e., the date on which the applicable version of the Notice was posted to the Platform). Our use of information we learn about you will be governed by the most recent Notice in effect. Your use of the Platform signifies your acceptance of the most recent Notice in effect.
<br/><br/>
The following supplements the <a href='https://maddogcarclub.io/#/terms' target='_blank'>https://maddogcarclub.io/#/terms</a> and <a href='https://maddogcarclub.io/#/policy' target='_blank'>https://maddogcarclub.io/#/policy</a> and explains the use of cookies and related technologies on the websites (“Sites”) operated by MDCC, Inc. and its affiliates (“MDCC”), including the Sites at <a href='https://maddogcarclub.io/#/policy' target='_blank'>https://maddogcarclub.io/#/policy</a> (including all of its subdomains). The MDCC entity that operates a Site is identified at that Site. The words “user,” “you” and “your” mean users of the Site, and the words “we,” “our” and “us” mean the MDCC entity that operates the Site.
<br/><br/>
COOKIES USED<br/>
The Sites employ browser-based, HTTP and/or HTTPS cookies, which are small text files placed on your computer's hard drive or mobile device's onboard storage and subsequently read by the domain owner that placed them as you visit a website, as well as web beacons (also known as pixel tags), which are electronic images embedded into a webpage, and HTML5 storage which allows data to be stored locally within the browser, in lieu of cookies. Cookies are used for a number of purposes, such as authentication, providing security, maintaining your preferences, advertising, website improvement and analytics. We may also engage third party vendors to use these technologies on our behalf.
<br/><br/>
We and/or our third-party vendors utilize session cookies, which expire typically at the end of your visit to our Sites, and persistent cookies, which are cookies that remain within your computer or mobile device over a period of time. In addition to our creating and placing a cookie on your computer or mobile device, we have arrangements with third party vendors which may also create and place cookies on your computer or mobile device as you visit the Sites. These third parties collect and store information to provide services to us, will change from time to time, and may include Google, Salesforce, DoubleClick, Quantcast, Pingdom, Nasdaq, Twitter, Pinterest, Facebook, AppNexus, Strikingly, The Trade Desk, Yahoo Ad Exchange, Marin Software, iGoDigital and MaxMind.
<br/><br/>
The first time you visit the Sites, we assign you a unique ID, which is stored in the cookie file on your computer or mobile device. This ID is used to identify you as you navigate the Sites, and when you return to the Sites after having visited elsewhere through the Internet. We collect and retain information regarding your visits to and navigation through the Sites, such as the webpages visited and the dates, times and duration of your visits.
<br/><br/>
DISABLING COOKIES<br/>
You may choose not to accept cookies through a setting on most browsers (which can also be set to notify you each time a cookie is set). If you choose to refuse cookies, or if you delete cookies, you may not be able to use all of the features of the Sites.
<br/><br/>
COLLECTION AND USE OF INFORMATION FROM COOKIES<br/>
SITES IMPROVEMENT AND ANALYTICS<br/>
To help us improve and enhance the Sites, we use cookies and web beacons for record keeping, to track your movements when you visit the Sites and to help us and our authorized third party analytics vendors to understand how the Sites are utilized. We may use this information in aggregate form or, alternatively, we may keep the information in an individual profile.
<br/><br/>
SECURITY AND FRAUD DETECTION<br/>
We and/or the third party vendors we engage may use the information generated from cookies in a way for that information to be linked with information to authenticate you for security and fraud detection purposes.
<br/><br/>
PREFERENCES<br/>
We use cookies to maintain your preferences on the Sites, such as to keep you logged into your account or to save various states and decisions you make while browsing the Sites.
<br/><br/>
COMPLIANCE WITH TERMS OF USE<br/>
We use IP addresses, MAC addresses and other computer or device identifying technologies to identify a user when we feel it is necessary to enforce compliance with our https://maddogcarclub.io/#/terms or to protect the Sites, our customers or other individuals.
<br/><br/>
MARKETING AND ADVERTISING<br/>
We use cookies and other technologies to identify that your web browser or mobile device has accessed areas of the Sites and may associate that information with your account, if you have one. We and/or our affiliates and/or the third party vendors we engage may use the information you provide and that is generated from cookies and other technologies in order to serve advertisements to you on our or our affiliates behalf and for our retailers, such as more relevant, interest-based advertising.
<br/><br/>
SHARING INFORMATION FROM COOKIES<br/>
We may share information collected from cookies and other technologies with third parties that provide services on our behalf. We may share such information with social media outlets to which you subscribe. We may share such information with third parties that provide marketing and advertising for us and with our retailers. We may also share such information with our affiliates, as outlined in our <a href='https://maddogcarclub.io/#/terms' target='_blank'>https://maddogcarclub.io/#/policy</a>.
<br/><br/>
We welcome your questions and comments about privacy, and what we do. Please feel free to contact us at support@maddogcarclub.io.`;

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-page">
      <div className="container">
        <h1 className="font-h1 font-bold text-center">PRIVACY POLICY</h1>
        <p className="font-terms font-light">{parse(PrivacyText1)}</p>
        <ul>
          {PrivacyListText1.map((item, index) => (
            <li className="font-terms font-light" key={index}>
              {parse(item)}
            </li>
          ))}
        </ul>
        <p className="font-terms font-light">{parse(PrivacyText2)}</p>
        <ul>
          {PrivacyListText2.map((item, index) => (
            <li className="font-terms font-light" key={index}>
              {parse(item)}
            </li>
          ))}
        </ul>
        <p className="font-terms font-light">{parse(PrivacyText3)}</p>
        <ul style={{ listStyleType: "number" }}>
          {PrivacyListText3.map((item, index) => (
            <li className="font-terms font-light" key={index}>
              {parse(item)}
            </li>
          ))}
        </ul>
        <p className="font-terms font-light">{parse(PrivacyText4)}</p>
        <ul style={{ listStyleType: "number" }}>
          {PrivacyListText4.map((item, index) => (
            <li className="font-terms font-light" key={index}>
              {parse(item)}
            </li>
          ))}
        </ul>
        <p className="font-terms font-light">{parse(PrivacyText5)}</p>
      </div>

    </div>
  );
};

export default Privacy;
