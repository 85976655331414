import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Link} from 'react-router-dom'
import "../styles/components/MintModal.scss";
// const fileDownload = require('react-file-download');


import Closebtn from "../assets/images/closeBtn.svg";
const MintModal = ({ show, onClose}) => {


  return (
    <Modal show={show} onHide={onClose} size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered className ='modal-mddog-detail'>


    <img src={Closebtn} width="30px" id="close-btn" onClick={onClose} height="30px" style = {{cursor: 'pointer'}} />



    
      <Modal.Body className = 'modal-body'>
        <p>MD RACERS<br/> MINT IS LIVE</p>
        <a className="mint-btn" target='_blank' href="https://maddogcarclub.ada-anvil.io/en/">MINT NOW</a>
      </Modal.Body>

    </Modal>
  );
};

export default MintModal;
