import React from "react";
import "@fontsource/barlow";
import "@fontsource/barlow/200.css";
import "@fontsource/barlow/400.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/600.css";
import "@fontsource/barlow/700.css";
import "@fontsource/barlow-condensed/700.css";
import "@fontsource/barlow-condensed/600.css";
import "@fontsource/barlow-condensed/500.css";
import "@fontsource/barlow-condensed/400.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.scss";
import Router from "./router";

function App() {
  return <Router />;
}

export default App;
