import React, { useEffect, useState } from "react";
import "../styles/madDogs.scss";
import MdRacers from "../assets/images/Md-racers.png";
import RoadMap from "../assets/images/mad-dog-roadmap.png";
import RoadMapMobile from "../assets/images/2AMClub_Mobile_Roadmap.png";
import Slick from "react-slick";
import SlickImage1 from "../assets/images/AVATAR_1.jpg";
import SlickImage2 from "../assets/images/AVATAR_3.png";
import SlickImage3 from "../assets/images/AVATAR_4.png";
import SlickImage4 from "../assets/images/AVATAR_6.png";
import SlickImage5 from "../assets/images/AVATAR_8.png";
import SlickImage6 from "../assets/images/AVATAR_10.png";
import SlickImage7 from "../assets/images/AVATAR_11.png";
import SlickImage8 from "../assets/images/AVATAR_12.png";
import SlickImage9 from "../assets/images/AVATAR_13.png";
import SlickImage10 from "../assets/images/PANDA.png";
import SlickImage11 from "../assets/images/AVATAR 14.png";
import SlickImage12 from "../assets/images/AVATAR 15.png";
import SlickImage13 from "../assets/images/AVATAR 16.png";
import SlickImage14 from "../assets/images/AVATAR 17.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMedia } from "react-use";
import { Link } from "react-router-dom";
import MintModal from "../components/MintModal";

const saleTexts = [
  {
    name: "Items",
    value: "9,999",
  },
  {
    name: "Owners",
    value: "1750",
  },
  {
    name: "Floor",
    value: "70",
  },
  {
    name: "Volume",
    value: "3.09m",
  },
];

const faqData = [
  {
    title: "when will MD racers mint?",
    text: "The MD Racers will be minted on the Cardano Blockchain on December 13th, 2022. ",
  },
  {
    title: "What was the mint price?",
    text: "For Whitelist, the price is 60 ADA. For Public, the price is 75 ADA.",
  },
  {
    title: "What is the total supply of md racers",
    text: "Total Supply is 9,999 MD Racers on the Cardano Blockchain.",
  },
  {
    title: "What is the main utility of MD racers?",
    text: "MD Racer will be your in game identity in MD Racing, you will be able to stake MD Racers for MD Tokens, receive quarterly airdropped MD Modification packages, access to other metaverses, and fully assembled car airdrops  from every future car collection for every 5 Racers held.",
  },
];

const utilityData = [
  "Non-Custodial Staking on MDToken.io",
  "Every 5 Racers that you hold you will be airdropped a fully assembled car from every future car collection.",
  "Every 3 months after the release of MD Racers each asset owner will be airdropped a MD modification pack which include exclusive car-skins, and other future car accessories.",
  "Access to other metaverses",
  "Access to MD Racing",
];

const slickData = [
  {
    img: SlickImage1,
  },
  {
    img: SlickImage2,
  },
  {
    img: SlickImage3,
  },
  {
    img: SlickImage4,
  },
  {
    img: SlickImage5,
  },
  {
    img: SlickImage6,
  },
  {
    img: SlickImage7,
  },
  {
    img: SlickImage8,
  },
  {
    img: SlickImage9,
  },
  {
    img: SlickImage10,
  },
  {
    img: SlickImage11,
  },
  {
    img: SlickImage12,
  },
  {
    img: SlickImage13,
  },
  {
    img: SlickImage14,
  },
];

const settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  centerPadding: "0px",
  speed: 500,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: "60px",
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 550,
      settings: {
        centerPadding: "120px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        centerPadding: "60px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        centerPadding: "20px",
        slidesToShow: 1,
      },
    },
  ],
};

const MadRacers = () => {
  const isMobile = useMedia("(max-width: 768px)");
  const [countTime, setCountTime] = useState("00");
  const [showMintModal, setShowMintModal] = useState(true)
  useEffect(() => {
    const now = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    const endTime = new Date("12/13/2022 04:00 pm").toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    const diffTime =
      (new Date(endTime).getTime() - new Date(now).getTime()) /
      (1000 * 3600 * 24);
    setCountTime("" + Math.ceil(diffTime));
  }, []);

  return (
    <div className="mad-racers">
      <section className="about-section">
        <div className="container d-flex">
          <div className="d-flex align-items-end centario-avatar">
            <div className="countdown-panel d-flex flex-column align-items-center justify-content-between">
              <div className="countdown-title font-bold">
                COUNTDOWN TILL MINT
              </div>
              <div className="countdown-items d-flex justify-content-center">
                <div className="countdown-item d-flex align-items-center justify-content-center font-bold">
                  
                  {
                    countTime[1] ? countTime[0] : '0'
                  }
                </div>
                <div className="countdown-item d-flex align-items-center justify-content-center font-bold">
                  {countTime[1] ? countTime[1] : countTime[0]}
                </div>
              </div>
              <div className="countdown-title font-bold">DAYS</div>
            </div>
            <img src={MdRacers} alt="md-racers-avatar" />
          </div>
          <div className="text-panel d-flex flex-column justify-content-center">
            <h1 className="font-title font-bold">MD RACERS</h1>
            <h4 className="font-h4 font-medium">
              Introducing MD Racers, our upcoming release under MDCC. These MD
              Racers will represent your identity in our Car Club. The
              collection consists of 9,999 algorithmically generated racers on
              the Cardano Blockchain.
            </h4>
            <h5 className="font-h5 font-light">
              Each MD Racer has their own unique personality. The utilization of
              these Racers will be implemented in MD Racing, and our future
              Metaverse, MD society. There are many other benefits you obtain by
              holding one or many of these Racers. The mint will be a 7 Days
              reveal after the Whitelist Sale.
            </h5>
            <div className="btn-panel d-flex align-items-center">
              <button
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">MINT NOW
              </button>
              {/* <button 
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">CHECK ALLOCATION
              </button> */}
              <Link to = "/check-allocation"
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">CHECK ALLOCATION
              </Link>
            </div>
            <div className="btn-panel d-flex align-items-center">
              <Link to = '/md-leaderboard'
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">LEADERBOARD
              </Link>
              {/* <button
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">LEADERBOARD
              </button>
              <button
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">MINT PROCESS
              </button> */}
              <Link to = '/mint-process'
                className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">MINT PROCESS
              </Link>

            </div>
          </div>
        </div>
      </section>
      <section className="sales-section">
        <div className="container d-flex flex-column align-items-center">
          <h1 className="font-h1 font-bold">SNEAK PEEKS</h1>
        </div>
        <div className="slick-container container">
          <Slick {...settings}>
            {slickData.map((item, index) => {
              return (
                <div
                  className="slick-item d-flex flex-column align-items-center"
                  key={index}
                >
                  <img src={item.img} alt="racers-avatar" />
                </div>
              );
            })}
          </Slick>
        </div>
        <div className="utility-panel">
          <div className="container d-flex">
            <div className="utility-text-panel w-50">
              <h1 className="font-title font-bold">UTILITY</h1>
              <ul>
                {utilityData.map((text, index) => (
                  <li key={index} className="font-h5 font-light">
                    {text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="utility-item-panel w-50 d-flex flex-column align-items-end">
              <div className="utility-item font-medium">
                Mint Date: December 13th
              </div>
              <div className="utility-item font-medium">WL Mint: 60 ADA</div>
              <div className="utility-item font-medium">
                Public Mint: 75 ADA
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="road-map-section">
        <div className="container">
          <img src={isMobile ? RoadMapMobile : RoadMap} alt="roadmap" />
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">
            FREQUENTLY ASKED QUESTIONS
          </h1>
          <div className="faq-text-panel">
            {faqData.map((faqItem, index) => (
              <div className="faq-item" key={index}>
                <h3 className="font-button1 font-bold">{faqItem.title}</h3>
                <p className="font-nav font-light">{faqItem.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <MintModal show = {showMintModal} onClose ={() => setShowMintModal(false)}  />
    </div>
  );
};

export default MadRacers;
