import React from "react";
import MDCC_Logo from "../assets/images/MDCC_Logo.png";
import "../styles/components/loading.scss";

const Loading = () => {
  return (
    <div className="loading-panel d-flex flex-column align-items-center justify-content-center">
      <img src={MDCC_Logo} className="mdcc-logo" alt="mdcc-logo" />
      <h3 className="loading-text text-center font-h3">LOADING...</h3>
    </div>
  );
};

export default Loading;
