import React from 'react'
import "../styles/checkAllocation.scss";
import parse from "html-react-parser";
import MintModal from '../components/MintModal';
import { useState } from 'react';
const tableData = [
    {
        wl: 'DIAMOND WL',
        wl_detail: 'DOG GOD<br/>DOG WHALE',
        mint_allocation: '12 MINT',
        ada: '720 ADA'
    },
    {
        wl: 'GOLD WL',
        wl_detail: 'DOG FATHER',
        mint_allocation: '10 MINT',
        ada: '600 ADA'
    },
    {
        wl: 'SILVER WL',
        wl_detail: 'DOG BOSSS',
        mint_allocation: '7 MINT',
        ada: '420 ADA'
    },
    {
        wl: 'BRONZE WL',
        wl_detail: 'DOG COLLECTOR',
        mint_allocation: '5 MINT',
        ada: '300 ADA'
    },
    {
        wl: 'WHITELIST',
        wl_detail: 'DOG HOLDER',
        mint_allocation: '3 MINT',
        ada: '180 ADA'
    },
    {
        wl: 'LVL 20 WL',
        wl_detail: '',
        mint_allocation: '3 MINT',
        ada: '180 ADA'
    },
    {
        wl: 'TWITTER WL',
        wl_detail: '',
        mint_allocation: '3 MINT',
        ada: '180 ADA'
    },
    {
        wl: 'LICENSE WL',
        wl_detail: 'MD LICENSE',
        mint_allocation: '3 MINT',
        ada: '180 ADA'
    },
    {
        wl: 'APEX DOG',
        wl_detail: '',
        mint_allocation: '3 MINT',
        ada: '180 ADA'
    },
    {
        wl: 'EACH TOP 1K',
        wl_detail: '',
        mint_allocation: '1 PER MINT',
        ada: '60 ADA'
    },
]
const CheckAllocation = () => {

  return (

<>
    <div className = 'check-allocation'>
        <div><div className = 'title-panel'>CHECK ALLOCATION <br />FOR WHITELIST </div>
        <div className = 'subtitle-panel'>PLEASE CALCULATE. ROLES ARE STACK-ABLE</div>
        <div className='table-panel'>
            <table className='table table-allocation table-responsive'>
                <thead>
                    <tr>
                        <th>ROLE</th>
                        <th>MINT ALLOCATION</th>
                        <th>ADA</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData.map((item, index) => {
                            return(
                                <tr className='align-middle'>
                                    <td>
                                        <span className='wl'>{item.wl}</span> <br/>
                                        <span className='wl-detail'>{parse(item.wl_detail)}</span>
                                    </td>
                                    <td>{item.mint_allocation}</td>
                                    <td>{ item.ada }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>        
        </div>
        </div>
    </div>

</>

  )
}

export default CheckAllocation