import React, { useState, useEffect } from "react";
import "../styles/madDogs.scss";
import CentarioAvatar from "../assets/images/Centario_Transparent.png";
import CarImage from "../assets/images/Rectangle1.png";
import RoadMap from "../assets/images/mad-dog-roadmap.png";
import RoadMapMobile from "../assets/images/2AMClub_Mobile_Roadmap.png";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MadDogCarImage1 from "../assets/images/BWX_T3_Cream 1.png";
import MadDogCarImage2 from "../assets/images/BWX_T3_Cream 3.png";
import MadDogCarImage3 from "../assets/images/BWX_T3_Cream 6.png";
import MadDogCarImage4 from "../assets/images/BWX_T3_Cream 2.png";
import MadDogCarImage5 from "../assets/images/BWX_T3_Cream 4.png";
import MadDogCarImage6 from "../assets/images/BWX_T3_Cream 5.png";
import axios from "axios";
import numeral from "numeral";
import { useMedia } from "react-use";
import { Link } from "react-router-dom";
import MintModal from "../components/MintModal";

const saleTexts = [
  {
    name: "Items",
    value: "9,999",
  },
  {
    name: "Owners",
    value: "1750",
  },
  {
    name: "Floor",
    value: "70",
  },
  {
    name: "Volume",
    value: "3.09m",
  },
];

const faqData = [
  {
    title: "When did the Mad Dog Car Collection release?",
    text: "The first car collection was released in July 2022.",
  },
  {
    title: "What was the mint price?",
    text: "The mint price was 60 ADA.",
  },
  {
    title: "What is the total supply of Mad Dog Car Collection?",
    text: "The first collection was 6,000 Car Parts with only 2,000 Cars able to be assembled.",
  },
  {
    title: "What is the Merge Mint and how does it work?",
    text: "You have to collect 3 Parts (Engine, Chassis and Wheels) of a single Car Model. Once the three car parts of a car are acquired, you can submit the parts to a specific address in the car assembly channel on discord.",
  },
  {
    title: "What is the main utility of a Car?",
    text: "Cars will play a huge role in our game MD Racing which will be your main asset to race against others. Fully assembled cars can be staked for MD Tokens, as well as customized with car accessories.",
  },
];

const utilityData = [
  "Non Custodial Staking on MDToken.io ",
  "Non Custodial Staking on CardanoLands",
  "Non Custodial Staking on Ape Society",
  "50% of Monthly Royalties to holders of 20+ Mad Dogs",
  "In-game Assets. ",
  "Customizable in-game",
  "Access to MD Racing",
];

const settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  centerPadding: "0px",
  speed: 500,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: "60px",
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 550,
      settings: {
        centerPadding: "120px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        centerPadding: "60px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        centerPadding: "20px",
        slidesToShow: 1,
      },
    },
  ],
};
const policyID = "0414065c7a2bbf91b876449384ba68a17aec83e8e08ab919262225ed";

const MadDogsCarsPage = () => {
  const [saleData, setSaleData] = useState(saleTexts);
  const [assetsData, setAssetsData] = useState([]);
  const isMobile = useMedia("(max-width: 768px)");
  const [highestSale, setHighestSale] = useState(0);
  const [showMintModal, setShowMintModal] = useState(true)
  const getPolicyData = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID
    );
    saleData[0].value = data.asset_minted;
    saleData[1].value = data.asset_holders;
    saleData[2].value = data.floor_price / 1000000;
    saleData[3].value = data.total_volume / 1000000;
    setHighestSale(data.highest_sale.price / 1000000);
    setSaleData([...saleData]);
  };

  const getPolicyTransations = async () => {
    const { data } = await axios.get(
      "https://api.opencnft.io/1/policy/" + policyID + "/transactions"
    );
    setAssetsData(data.items);
  };

  useEffect(() => {
    getPolicyData();
    getPolicyTransations();
  }, []);
  return (
    <div className="mad-dog-cars-page">
      <section className="about-section">
        <div className="container d-flex">
          <div className="d-flex images-panel">
            <div className="left-images-panel d-flex flex-column">
              <img src={MadDogCarImage1} alt="mad-dog-car-image" />
              <img src={MadDogCarImage2} alt="mad-dog-car-image" />
              {!isMobile && (
                <img src={MadDogCarImage3} alt="mad-dog-car-image" />
              )}
            </div>
            <div className="right-images-panel d-flex flex-column">
              <img src={MadDogCarImage4} alt="mad-dog-car-image" />
              <img src={MadDogCarImage5} alt="mad-dog-car-image" />
              <img src={MadDogCarImage6} alt="mad-dog-car-image" />
            </div>
          </div>
          <div className="text-panel d-flex flex-column justify-content-center">
            <h1 className="font-title font-bold">MAD DOG CARS</h1>
            <h4 className="font-h4 font-medium">
              A variety of Car Parts that you can assemble to create a fully
              built Mad Dog Car. Each Mad Dog Car will be utilized in our
              Play-2-Earn Game: MD Racing. MD Racing will give "utility" a whole
              new meaning.
            </h4>
            <h4 className="font-h4 font-light">Hop in and enjoy the ride.</h4>
            {/* <div className="btn-panel d-flex align-items-center">
              <button className="btn btn-whitepaper font-nav font-bold d-flex align-items-center justify-content-center">WHITEPAPER</button>
            </div> */}
          </div>
        </div>
      </section>
      <section className="sales-section">
        <div className="container d-flex flex-column align-items-center">
          <h1 className="font-h1 font-bold">LATEST SALES</h1>
          <div className="sale-text-panel d-flex">
            {saleData.map((saleText, index) => {
              let value = saleText.value;
              if (String(value).length > 4) {
                value = numeral(value).format("0.0 a");
              }
              return (
                <div className="sale-text-item text-center" key={index}>
                  <p className="sale-text_value font-normal">{value}</p>
                  <p className="sale-text_label font-light">{saleText.name}</p>
                </div>
              );
            })}
          </div>
          <a
            target="_blank"
            href="https://www.jpg.store/collection/maddogcarcollection"
            className="visit-btn font-button2 font-bold"
            rel="noreferrer"
          >
            Visit JPG.STORE
          </a>
        </div>
        <div className="slick-container container">
          <Slick {...settings}>
            {assetsData.map((item, index) => {
              const imageId = item?.thumbnail.thumbnail.replaceAll(
                "ipfs://",
                ""
              );
              let seconds = (+new Date() - item?.sold_at * 1000) / 1000;
              let time = "";
              if (seconds / 3600 < 1) {
                time =
                  time +
                  ((seconds % 3600) / 60).toFixed() +
                  (((seconds % 3600) / 60).toFixed() ? " minutes" : " minute");
              } else {
                if (seconds / 3600 > 23) {
                  time =
                    (seconds / 3600 / 24).toFixed() +
                    ((seconds / 3600 / 24).toFixed() > 1 ? " days " : " day ");
                }
                time =
                  time +
                  ((seconds % (3600 * 24)) / 3600).toFixed() +
                  (((seconds % (3600 * 24)) / 3600).toFixed() > 1
                    ? " hours "
                    : " hour ");
                time =
                  time +
                  (((seconds % (3600 * 24)) % 3600) / 60).toFixed() +
                  " minutes";
              }
              return (
                <div
                  className="slick-item d-flex flex-column align-items-center"
                  key={index}
                >
                  <img
                    src={`https://image-optimizer.jpgstoreapis.com/${imageId}/?width=600`}
                    alt="dog-avatar"
                  />
                  <p className="slick-text font-light text-center">
                    Sold {time} ago
                  </p>
                </div>
              );
            })}
          </Slick>
        </div>
        <div className="utility-panel">
          <div className="container d-flex">
            <div className="utility-text-panel w-50">
              <h1 className="font-title font-bold">UTILITY</h1>
              <ul>
                {utilityData.map((text, index) => (
                  <li key={index} className="font-h5 font-light">
                    {text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="utility-item-panel w-50 d-flex flex-column align-items-end">
              <div className="utility-item font-medium">
                Highest Sale: {highestSale} ADA
              </div>
              <div className="utility-item font-medium">
                Total Royalties Distributed: 5,588 ADA
              </div>
              <div className="utility-item font-medium">
                Total Staking Platforms: 3
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="road-map-section">
        <div className="container">
          <img src={isMobile ? RoadMapMobile : RoadMap} alt="roadmap" />
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <h1 className="font-h1 font-bold text-center">
            FREQUENTLY ASKED QUESTIONS
          </h1>
          <div className="faq-text-panel">
            {faqData.map((faqItem, index) => (
              <div className="faq-item" key={index}>
                <h3 className="font-button1 font-bold">{faqItem.title}</h3>
                <p className="font-nav font-light">{faqItem.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <MintModal show = {showMintModal} onClose ={() => setShowMintModal(false)}  />
    </div>
  );
};

export default MadDogsCarsPage;
